//Font Import
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

//Variables
$darker: #0C233D;
$dark: #0B223E;
$green: #00E881;
$dark_green: #00E2BD;
$blue: #00DCFD;
$light_blue: #26DCFB;
$light_grey: #F2F3F5;

//HTML tags
html {
    font-size: 18px;
}

body {
    font-family: 'Poppins', sans-serif;
    color: $dark;
    background-color: $light_grey;
}

p {
    margin-top: 0;
    font-size: clamp(0.77rem, calc(0.7169rem + 0.2654vw), 1rem);
}

h1 {
    font-size: clamp(1.44rem, calc(0.8769rem + 2.8154vw), 3.43rem);
    font-weight: 600;
    line-height: 1.3;
    margin-top: 0;
}

h2 {
    font-size: clamp(1.11rem, calc(0.5975rem + 2.5627vw), 3.331rem);
    font-weight: 600;
    margin-top: 0;
}

a {
    text-decoration: none;
}

a, button, input {
    transition: all .3s;
    &:focus, &:visited, &:active {
        box-shadow: none;
        outline: none;
    }

    &:hover {
        opacity: .7;
    }
}

//Helpers
.container {
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
    @media(min-width: 992px) { max-width: 85vw; }
    @media(min-width: 1920px) { max-width: 1636px; }
    @media(max-width: 991px) { width: initial; }
}

.text-center { text-align: center; }

.btn {
    display: flex;
    font-weight: 600;
    font-size: clamp(0.77rem, calc(0.0900rem + 0.9563vw), 1.11rem);
    padding: 16px 43px;
    border-radius: 100px;
    width: fit-content;
    align-items: center;

    &__primary {
        background-color: $green;
        color: $dark;
        &:hover {
            i {
                animation: spin 1s infinite;
            }
        }
    }
}

@keyframes spin {
    0% { transform: rotate(0); }
    100% { transform: rotate(360deg); }
}

.icon {
    display: inline-block;
    background-size: 100%;
    background-repeat: no-repeat;

    &__btn {
        background-image: url('../images/button_icon.svg');
        width: 22.51px;
        height: 22.46px;
        margin-left: 16.44px;
    }
}

//Navigation
#nav {
    padding: 25px 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 111;
    transition: all .3s;
    @media(max-width: 991px) { padding: 0; }
    @media(max-width: 767px) { width: 100vw; }

    &.dark-nav {
        background-color: $darker;
    }

    .container {
        display: flex;
        justify-content: space-between;
        @media(max-width: 991px) { align-items: center; }
    }

    .logo {
        width: clamp(5.55rem, calc(3.7175rem + 9.1627vw), 13.491rem);

        svg {
            width: 100%;
        }
    }

    .icon {
        &__menu {
            width: 30px;
            height: 30px;
            border: none;
            background-image: url('../images/menu.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            background-color: transparent;
            @media(min-width: 992px) { display: none; }
        }
    }

    ul {
        padding-left: 0;
        display: flex;
        justify-content: space-between;
        list-style: none;
        align-items: center;
        @media(min-width: 992px) { margin-left: auto; }
        @media(max-width: 992px) {
            display: block;
            position: absolute;
            width: 100%;
            flex-wrap: wrap;
            background-color: $darker;
            left: 0;
            top: 83px;
            padding-bottom: 30px;
            transition: all 1s;
            transform-origin: top;
            transform: translateY(-200%);
            opacity: 0;

            &.open {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }

    li {
        margin: 0 22px;
        padding: 10px 0;
        @media(max-width: 991px) {
            margin: 5px 22px;
            text-align: center;
        }

        a {
            color: #fff;
            font-size: clamp(0.77rem, calc(0.0900rem + 0.9563vw), 1.11rem);
            @media(max-width: 991px) { font-size: 18px; }

            &:hover {
                cursor: pointer;
            }

            &.btn {
                color: $dark;
            }
        }
    }

    button.icon__search {
        background-color: transparent;
        border: none;
    }

    .dropdown-menu {
        display: block;
        pointer-events: none;
        position: absolute;
        background-color: $darker;
        padding: 15px;
        width: 120%;
        opacity: 0;
        transition: all .3s;
        @media(max-width: 991px) {
            position: static;
            pointer-events: all;
            opacity: 1;
            background-color: transparent;
            padding: 0;
            width: initial;
            display: none;

            li {
                padding: 0;
            }
        }

        &:hover {
            pointer-events: all;
            opacity: 1;
        }

        li {
            padding: 15px 0;
        }
    }

    .dropdown {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            display: inline-block;
            background-image: url('../images/arrow_down_icon.svg');
            background-repeat: no-repeat;
            width: 14px;
            height: 100%;
            right: -20px;
            bottom: 0;
            background-position: center;
            @media(max-width: 991px) { content: none; }
        }

        &:hover {
            .dropdown-menu {
                pointer-events: all;
                opacity: 1;
                @media(max-width: 991px) { display: contents; }
            }
        }
    }
}

//Article Header
.articles__header {
    background-image: url('../images/header_image.svg');
    background-size: cover;
    height: 59vh;
    display: flex;
    align-items: center;
    justify-content: center;

    >div {
        padding-top: 100px; 
        @media(min-width: 1199px) {
            padding-left: clamp(5.55rem, calc(-12.7940rem + 25.7962vw), 14.722rem);
            padding-right: clamp(5.55rem, calc(-12.7940rem + 25.7962vw), 14.722rem);
        }
    }

    p {
        color: $green;
        font-size: clamp(1rem, calc(0.8477rem + 0.7615vw), 1.5rem);
        margin-bottom: 26px;
    }

    h1 {
        color: #fff;
    }
}

//Articles
.categories {
    width: 100%;

    ul {
        display: flex;
        justify-content: center;
        gap: 21px;
        list-style: none;
        padding-left: 0;
        @media(max-width: 991px) {
            flex-wrap: wrap;
            justify-content: space-between;
        }

        li {
            @media(max-width: 767px) { width: 100%; }
        }

        button {
            background-color: transparent;
            border: 2px solid $light_blue;
            font-size: clamp(0.77rem, calc(0.6915rem + 0.3923vw), 1rem);
            font-weight: 600;
            padding: 16px 40px;
            border-radius: 100px;
            transition: all .3s;
            @media(max-width: 767px) { width: 100%; }

            &:hover, &.active {
                background-color: $light_blue;
                cursor: pointer;
            }
        }
    }
}

@keyframes fade-up {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.articles {
    display: flex;
    flex-wrap: wrap;
    gap: 55px;
    padding-top: 72.5px;
    padding-bottom: 89px;
    @media(max-width: 1300px) { gap: 30px; }
    @media(max-width: 767px) {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .article {
        width: 30%;
        border-radius: 10px 10px 0 0;
        transition: all .3s;
        opacity: 0;
        animation: fade-up 1s forwards;
        @media(max-width: 767px) { width: 100%; }

        &:hover {
            background-color: $light_blue;
        }

        &:nth-child(even) {
            animation-delay: 1s;
        }

        img {
            width: 100%;
        }

        .image {
            position: relative;

            .cat {
                background-color: $blue;
                border-radius: 0 15px 15px 0;
                width: fit-content;
                color: $dark;
                padding: 14px 35px 14px 28px;
                position: absolute;
                left: 0;
                bottom: -20px;
                font-size: 14px;
            }
        }

        h2 {
            font-size: clamp(1rem, calc(0.8846rem + 0.5128vw), 1.5rem);
        }

        .content {
            padding: 77px 26px 46px 26px;
            border-left: 3px solid $light_blue;
            border-right: 3px solid $light_blue;
            border-bottom: 3px solid $light_blue;
            border-radius: 0 0 10px 10px;
            transition: all .3s;

            &:hover {
                cursor: pointer;
            }

            a {
                color: $dark;
            }

            p {
                display: flex;
                align-items: center;
                font-size: 12px;

                i {
                    margin-right: 15px;
                    margin-left: 0;
                }
            }
        }
    }
}

.pagination-wrapper {
    width: 100%;

    ul {
        display: flex;
        justify-content: center;
        gap: 20px;
        list-style: none;

        button {
            background-color: transparent;
            border-radius: 50%;
            border: 3px solid transparent;
            transition: all .3s;
            width: 62px;
            height: 62px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;

            &:hover {
                border-color: $light_blue;
            }

            &.active {
                border-color: $light_blue;
            }
        }
    }
}

//Single Article
.article__header {
    background-color: $dark;
    background-size: cover;
    height: 77vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 6%;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $darker;
        opacity: .7;
    }

    h1 {
        color: #fff;
        margin-bottom: 52px;
        font-size: clamp(1.44rem, calc(1.0512rem + 1.7282vw), 3.125rem);
    }

    .container {
        display: flex;
        position: relative;
        z-index: 1;
        justify-content: space-between;
        align-items: flex-end;
        @media(max-width: 767px) { flex-wrap: wrap; }
    }

    .cat {
        background-color: $blue;
        border-radius: 0 15px 15px 0;
        width: fit-content;
        color: $dark;
        padding: 14px 35px 14px 28px;
        margin-bottom: 20px;
        font-size: 14px;
    }

    .date {
        @media(max-width: 767px) { margin-bottom: 30px; }
        p {
            margin-bottom: 0;
            color: #fff;
            font-size: 14px;
            display: flex;
            align-items: center;

            .icon {
                &__time {
                    background-image: url('../images/time_icon_white.svg');
                    margin-right: 20px;
                }
            }
        }
    }

    .socials {
        p {
            color: $green;
            text-align: right;
            font-size: 14px;
            @media(max-width: 767px) { text-align: left; }
        }

        ul {
            list-style: none;
            display: flex;
            justify-content: flex-end;
            gap: 18px;
            margin-bottom: 0;
            @media(max-width: 767px) { padding-left: 0; }
        }

        .icon {
            &__linkedin, &__facebook {
                border: none;
            }

            &__linkedin {
                background-image: url('../images/linkedin_icon_green.svg');
                width: 33px;
                height: 18px;
            }

            &__facebook {
                background-image: url('../images/facebook_icon_green.svg');
                width: 30px;
                height: 18px;
            }
        }
    }
}

.article-content {
    padding-top: 110px;
    padding-bottom: 200px;
    max-width: 1350px;
    @media(max-width: 1700px) {
        max-width: 85vw;
    }

    p {
        font-size: clamp(0.77rem, calc(0.7169rem + 0.2654vw), 1rem);
        line-height: 2;
        margin-bottom: 45px;
    }

    .wpb_text_column p:last-child {
        margin-bottom: 10px;
    }

    h3 {
        font-size: clamp(1rem, calc(0.8477rem + 0.7615vw), 1.66rem);
        margin-bottom: 30px !important; 
    }

    h3, p {
        padding: 0 130px;
        @media(max-width: 991px) { padding: 0 20px; }
    }

    .vc_figure {
        width: 100%;

        .vc_single_image-wrapper {
            width: 100%;

            img {
                width: 100%;
                height: 100%;
                border-radius: 15px;
            }
        }
    }

    .vc_col-sm-6 {
        margin-top: 40px;
        p {
            padding: 0 90px 0 64px;
            @media(max-width: 991px) { padding: 0 20px; }
        }
    }

    img {
        width: 100%;
        height: auto;
    }
}

.other-articles {
    display: flex;
    justify-content: space-between;
    background-color: $dark;
    @media(max-width: 991px) { flex-wrap: wrap; }
    
    .prev, .next {
        padding: 80px;
        color: #fff;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center;
        position: relative;
        @media(max-width: 991px) { width: 100%; }
        @media(max-width: 767px) {padding: 40px; }

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            width: 100%;
            height: 100%;
            inset: 0;
            background-color: $darker;
            opacity: .4;
            transition: all .3s;
        }

        a {
            color: $green;
            position: relative;
            z-index: 1;
            line-height: 1.9; 
            font-size: 14px;

            strong {
                color: #fff;
                font-size: 24px;
                @media(max-width: 767px) { font-size: 16px; }
            }
        }

        &:hover {
            &::before {
                background-color: $light_blue;
                opacity: .6;
            }

            &::after {
                content: '';
                position: absolute;
                width: 33px;
                height: 22px;
                background-size: 100%;
                background-repeat: no-repeat;
                background-image: url('../images/arrow-right-line.png');
                transform: translateX(-50%);
                @media(max-width: 767px) { content: none; }
            }

            a {
                color: $darker;
                strong {
                    color: $darker;
                }
            }
        }
    }

    .prev {
        &::after {
            transform: rotate(180deg) translateX(-50%) !important;
            left: 24%;
            top: 45%;
        }
    }

    .next {
        &::after {
            right: 24%;
            top: 45%;
        }
        a {
            text-align: right;
        }
    }
}

//icons
i.icon, button.icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;

    &__phone, &__mail, &__facebook, &__instagram, &__linkedin {
        background-image: url('../images/phone_icon.svg');
        width: clamp(1.66rem, calc(-0.4256rem + 3.7843vw), 3.611rem);
        height: clamp(1.66rem, calc(-0.4256rem + 3.7843vw), 3.611rem);
        border: 2px solid $green;
        border-radius: 15px;
        background-size: 60%;
    }

    &__mail {
        background-image: url('../images/mail_icon.svg');
    }

    &__facebook, &__instagram, &__linkedin {
        background-image: url('../images/facebook_icon.svg');
        border: 2px solid $light_blue;
    }

    &__instagram {
        background-image: url('../images/instagram_icon.svg');
    }

    &__linkedin {
        background-image: url('../images/linkedin_icon.svg');
    }

    &__search {
        background-image: url('../images/search_icon.svg');
        width: 21.3px;
        height: 21.3px;
        background-size: 100%;
    }

    &__google {
        background-image: url('../images/google_icon.svg');
        width: 18px;
        height: 18px;
    }

    &__twitter {
        background-image: url('../images/twitter_icon.svg');
        width: 22px;
        height: 20px;
    }

    &__email {
        background-image: url('../images/email_icon.svg');
        width: 20px;
        height: 18px;
    }

    &__time {
        background-image: url('../images/time_icon.svg');
        width: 33px;
        height: 33px;
    }
}

//Footer
#footer {
    background-color: $dark;

    .top {
        height: 52vh;
        background-image: url('../images/footer_image.svg');
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;

        .container {
            padding-top: 4%;
        }

        h2 {
            color: #fff;
            margin-bottom: 36px;
        }

        p {
            color: $green;
            margin-bottom: 14px;
            font-size: clamp(1rem, calc(0.8477rem + 0.7615vw), 1.66rem);
        }

        a {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .bottom {
        .logo {
            width: clamp(5.55rem, calc(3.7175rem + 9.1627vw), 13.491rem);
            display: inline-block;

            svg {
                width: 100%;
                margin-bottom: -10px;
                @media(max-width: 1400px) { margin-bottom: -15px; }
            }
        }
        .legal {
            padding-bottom: 88px;
            padding-top: 61px;
            display: flex;
            @media(max-width: 991px) { 
                flex-wrap: wrap; 
                width: initial;
            }
            
            ul {
                display: flex;
                justify-content: space-between;
                align-items: center;
                list-style: none;
                margin-bottom: 0;
                margin-top: 0;
                margin-left: auto;
                @media(max-width: 991px) { 
                    width: 100%;
                    order: 1;
                    padding-left: 0;
                    justify-content: flex-start;
                    margin-left: 0;
                    margin-bottom: 30px;
                }
                @media(max-width: 767px) { display: block; }

                li {
                    color: #fff;
                    margin: 0 40px;
                    @media(max-width: 991px) { margin-left: 0; }
                    @media(max-width: 767px) { margin-bottom: 15px; }

                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        font-size: clamp(0.61rem, calc(-0.3900rem + 1.4063vw), 1.11rem);
                        color: #fff;
                    }
                }
            }

            .copyright {
                @media(max-width: 991px) { 
                    width: 100%;
                    order: 2;
                }
                p {
                    margin-bottom: 0;
                    font-size: clamp(0.66rem, calc(0.3200rem + 0.4781vw), 0.83rem);
                    color: #fff;
                    line-height: 1.8;

                    br {
                        @media(max-width: 991px) { text-align: center; }
                    }

                    a {
                        color: #fff;
                    }
                }
            }
        }

        .links {
            border-bottom: 1px solid $light_blue;
            padding-bottom: 72px;
            padding-top: 120px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            @media(max-width: 991px) {
                flex-wrap: wrap;
            }

            .brand {
                @media(max-width: 991px) {
                    width: 100%;
                    margin-bottom: 30px;
                } 
            }

            .contact {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                gap: 50px;
                @media(max-width: 767px) { flex-wrap: wrap; }
            }

            .detail {
                p {
                    color: #fff;
                    font-size: clamp(0.66rem, calc(0.5562rem + 0.5192vw), 1.11rem);
                    margin-bottom: 0;
                }

                a {
                    color: $green;
                    font-weight: 600;
                    font-size: clamp(0.77rem, calc(-0.1814rem + 1.7263vw), 1.66rem);
                }
            }

            .item {
                display: flex;

                .icons {
                    margin-right: 18px;
                }
            }

            .social {
                gap: 25px;
                display: flex;
            }
        }
    }
}