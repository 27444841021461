@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
html {
  font-size: 18px;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #0B223E;
  background-color: #F2F3F5;
}

p {
  margin-top: 0;
  font-size: clamp(0.77rem, calc(0.7169rem + 0.2654vw), 1rem);
}

h1 {
  font-size: clamp(1.44rem, calc(0.8769rem + 2.8154vw), 3.43rem);
  font-weight: 600;
  line-height: 1.3;
  margin-top: 0;
}

h2 {
  font-size: clamp(1.11rem, calc(0.5975rem + 2.5627vw), 3.331rem);
  font-weight: 600;
  margin-top: 0;
}

a {
  text-decoration: none;
}

a, button, input {
  -webkit-transition: all .3s;
  transition: all .3s;
}

a:focus, a:visited, a:active, button:focus, button:visited, button:active, input:focus, input:visited, input:active {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

a:hover, button:hover, input:hover {
  opacity: .7;
}

.container {
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

@media (min-width: 992px) {
  .container {
    max-width: 85vw;
  }
}

@media (min-width: 1920px) {
  .container {
    max-width: 1636px;
  }
}

@media (max-width: 991px) {
  .container {
    width: initial;
  }
}

.text-center {
  text-align: center;
}

.btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
  font-size: clamp(0.77rem, calc(0.0900rem + 0.9563vw), 1.11rem);
  padding: 16px 43px;
  border-radius: 100px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.btn__primary {
  background-color: #00E881;
  color: #0B223E;
}

.btn__primary:hover i {
  -webkit-animation: spin 1s infinite;
          animation: spin 1s infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.icon {
  display: inline-block;
  background-size: 100%;
  background-repeat: no-repeat;
}

.icon__btn {
  background-image: url("../images/button_icon.svg");
  width: 22.51px;
  height: 22.46px;
  margin-left: 16.44px;
}

#nav {
  padding: 25px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 111;
  -webkit-transition: all .3s;
  transition: all .3s;
}

@media (max-width: 991px) {
  #nav {
    padding: 0;
  }
}

@media (max-width: 767px) {
  #nav {
    width: 100vw;
  }
}

#nav.dark-nav {
  background-color: #0C233D;
}

#nav .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 991px) {
  #nav .container {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

#nav .logo {
  width: clamp(5.55rem, calc(3.7175rem + 9.1627vw), 13.491rem);
}

#nav .logo svg {
  width: 100%;
}

#nav .icon__menu {
  width: 30px;
  height: 30px;
  border: none;
  background-image: url("../images/menu.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: transparent;
}

@media (min-width: 992px) {
  #nav .icon__menu {
    display: none;
  }
}

#nav ul {
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  list-style: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (min-width: 992px) {
  #nav ul {
    margin-left: auto;
  }
}

@media (max-width: 992px) {
  #nav ul {
    display: block;
    position: absolute;
    width: 100%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    background-color: #0C233D;
    left: 0;
    top: 83px;
    padding-bottom: 30px;
    -webkit-transition: all 1s;
    transition: all 1s;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform: translateY(-200%);
            transform: translateY(-200%);
    opacity: 0;
  }
  #nav ul.open {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

#nav li {
  margin: 0 22px;
  padding: 10px 0;
}

@media (max-width: 991px) {
  #nav li {
    margin: 5px 22px;
    text-align: center;
  }
}

#nav li a {
  color: #fff;
  font-size: clamp(0.77rem, calc(0.0900rem + 0.9563vw), 1.11rem);
}

@media (max-width: 991px) {
  #nav li a {
    font-size: 18px;
  }
}

#nav li a:hover {
  cursor: pointer;
}

#nav li a.btn {
  color: #0B223E;
}

#nav button.icon__search {
  background-color: transparent;
  border: none;
}

#nav .dropdown-menu {
  display: block;
  pointer-events: none;
  position: absolute;
  background-color: #0C233D;
  padding: 15px;
  width: 120%;
  opacity: 0;
  -webkit-transition: all .3s;
  transition: all .3s;
}

@media (max-width: 991px) {
  #nav .dropdown-menu {
    position: static;
    pointer-events: all;
    opacity: 1;
    background-color: transparent;
    padding: 0;
    width: initial;
    display: none;
  }
  #nav .dropdown-menu li {
    padding: 0;
  }
}

#nav .dropdown-menu:hover {
  pointer-events: all;
  opacity: 1;
}

#nav .dropdown-menu li {
  padding: 15px 0;
}

#nav .dropdown {
  position: relative;
}

#nav .dropdown::before {
  content: '';
  position: absolute;
  display: inline-block;
  background-image: url("../images/arrow_down_icon.svg");
  background-repeat: no-repeat;
  width: 14px;
  height: 100%;
  right: -20px;
  bottom: 0;
  background-position: center;
}

@media (max-width: 991px) {
  #nav .dropdown::before {
    content: none;
  }
}

#nav .dropdown:hover .dropdown-menu {
  pointer-events: all;
  opacity: 1;
}

@media (max-width: 991px) {
  #nav .dropdown:hover .dropdown-menu {
    display: contents;
  }
}

.articles__header {
  background-image: url("../images/header_image.svg");
  background-size: cover;
  height: 59vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.articles__header > div {
  padding-top: 100px;
}

@media (min-width: 1199px) {
  .articles__header > div {
    padding-left: clamp(5.55rem, calc(-12.7940rem + 25.7962vw), 14.722rem);
    padding-right: clamp(5.55rem, calc(-12.7940rem + 25.7962vw), 14.722rem);
  }
}

.articles__header p {
  color: #00E881;
  font-size: clamp(1rem, calc(0.8477rem + 0.7615vw), 1.5rem);
  margin-bottom: 26px;
}

.articles__header h1 {
  color: #fff;
}

.categories {
  width: 100%;
}

.categories ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 21px;
  list-style: none;
  padding-left: 0;
}

@media (max-width: 991px) {
  .categories ul {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

@media (max-width: 767px) {
  .categories ul li {
    width: 100%;
  }
}

.categories ul button {
  background-color: transparent;
  border: 2px solid #26DCFB;
  font-size: clamp(0.77rem, calc(0.6915rem + 0.3923vw), 1rem);
  font-weight: 600;
  padding: 16px 40px;
  border-radius: 100px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

@media (max-width: 767px) {
  .categories ul button {
    width: 100%;
  }
}

.categories ul button:hover, .categories ul button.active {
  background-color: #26DCFB;
  cursor: pointer;
}

@-webkit-keyframes fade-up {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes fade-up {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.articles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 55px;
  padding-top: 72.5px;
  padding-bottom: 89px;
}

@media (max-width: 1300px) {
  .articles {
    gap: 30px;
  }
}

@media (max-width: 767px) {
  .articles {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

.articles .article {
  width: 30%;
  border-radius: 10px 10px 0 0;
  -webkit-transition: all .3s;
  transition: all .3s;
  opacity: 0;
  -webkit-animation: fade-up 1s forwards;
          animation: fade-up 1s forwards;
}

@media (max-width: 767px) {
  .articles .article {
    width: 100%;
  }
}

.articles .article:hover {
  background-color: #26DCFB;
}

.articles .article:nth-child(even) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.articles .article img {
  width: 100%;
}

.articles .article .image {
  position: relative;
}

.articles .article .image .cat {
  background-color: #00DCFD;
  border-radius: 0 15px 15px 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #0B223E;
  padding: 14px 35px 14px 28px;
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 14px;
}

.articles .article h2 {
  font-size: clamp(1rem, calc(0.8846rem + 0.5128vw), 1.5rem);
}

.articles .article .content {
  padding: 77px 26px 46px 26px;
  border-left: 3px solid #26DCFB;
  border-right: 3px solid #26DCFB;
  border-bottom: 3px solid #26DCFB;
  border-radius: 0 0 10px 10px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.articles .article .content:hover {
  cursor: pointer;
}

.articles .article .content a {
  color: #0B223E;
}

.articles .article .content p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
}

.articles .article .content p i {
  margin-right: 15px;
  margin-left: 0;
}

.pagination-wrapper {
  width: 100%;
}

.pagination-wrapper ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 20px;
  list-style: none;
}

.pagination-wrapper ul button {
  background-color: transparent;
  border-radius: 50%;
  border: 3px solid transparent;
  -webkit-transition: all .3s;
  transition: all .3s;
  width: 62px;
  height: 62px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 600;
}

.pagination-wrapper ul button:hover {
  border-color: #26DCFB;
}

.pagination-wrapper ul button.active {
  border-color: #26DCFB;
}

.article__header {
  background-color: #0B223E;
  background-size: cover;
  height: 77vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  padding-top: 6%;
}

.article__header::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #0C233D;
  opacity: .7;
}

.article__header h1 {
  color: #fff;
  margin-bottom: 52px;
  font-size: clamp(1.44rem, calc(1.0512rem + 1.7282vw), 3.125rem);
}

.article__header .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 1;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

@media (max-width: 767px) {
  .article__header .container {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

.article__header .cat {
  background-color: #00DCFD;
  border-radius: 0 15px 15px 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #0B223E;
  padding: 14px 35px 14px 28px;
  margin-bottom: 20px;
  font-size: 14px;
}

@media (max-width: 767px) {
  .article__header .date {
    margin-bottom: 30px;
  }
}

.article__header .date p {
  margin-bottom: 0;
  color: #fff;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.article__header .date p .icon__time {
  background-image: url("../images/time_icon_white.svg");
  margin-right: 20px;
}

.article__header .socials p {
  color: #00E881;
  text-align: right;
  font-size: 14px;
}

@media (max-width: 767px) {
  .article__header .socials p {
    text-align: left;
  }
}

.article__header .socials ul {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  gap: 18px;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .article__header .socials ul {
    padding-left: 0;
  }
}

.article__header .socials .icon__linkedin, .article__header .socials .icon__facebook {
  border: none;
}

.article__header .socials .icon__linkedin {
  background-image: url("../images/linkedin_icon_green.svg");
  width: 33px;
  height: 18px;
}

.article__header .socials .icon__facebook {
  background-image: url("../images/facebook_icon_green.svg");
  width: 30px;
  height: 18px;
}

.article-content {
  padding-top: 110px;
  padding-bottom: 200px;
  max-width: 1350px;
}

@media (max-width: 1700px) {
  .article-content {
    max-width: 85vw;
  }
}

.article-content p {
  font-size: clamp(0.77rem, calc(0.7169rem + 0.2654vw), 1rem);
  line-height: 2;
  margin-bottom: 45px;
}

.article-content .wpb_text_column p:last-child {
  margin-bottom: 10px;
}

.article-content h3 {
  font-size: clamp(1rem, calc(0.8477rem + 0.7615vw), 1.66rem);
  margin-bottom: 30px !important;
}

.article-content h3, .article-content p {
  padding: 0 130px;
}

@media (max-width: 991px) {
  .article-content h3, .article-content p {
    padding: 0 20px;
  }
}

.article-content .vc_figure {
  width: 100%;
}

.article-content .vc_figure .vc_single_image-wrapper {
  width: 100%;
}

.article-content .vc_figure .vc_single_image-wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.article-content .vc_col-sm-6 {
  margin-top: 40px;
}

.article-content .vc_col-sm-6 p {
  padding: 0 90px 0 64px;
}

@media (max-width: 991px) {
  .article-content .vc_col-sm-6 p {
    padding: 0 20px;
  }
}

.article-content img {
  width: 100%;
  height: auto;
}

.other-articles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #0B223E;
}

@media (max-width: 991px) {
  .other-articles {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

.other-articles .prev, .other-articles .next {
  padding: 80px;
  color: #fff;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-size: cover;
  background-position: center;
  position: relative;
}

@media (max-width: 991px) {
  .other-articles .prev, .other-articles .next {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .other-articles .prev, .other-articles .next {
    padding: 40px;
  }
}

.other-articles .prev::before, .other-articles .next::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  background-color: #0C233D;
  opacity: .4;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.other-articles .prev a, .other-articles .next a {
  color: #00E881;
  position: relative;
  z-index: 1;
  line-height: 1.9;
  font-size: 14px;
}

.other-articles .prev a strong, .other-articles .next a strong {
  color: #fff;
  font-size: 24px;
}

@media (max-width: 767px) {
  .other-articles .prev a strong, .other-articles .next a strong {
    font-size: 16px;
  }
}

.other-articles .prev:hover::before, .other-articles .next:hover::before {
  background-color: #26DCFB;
  opacity: .6;
}

.other-articles .prev:hover::after, .other-articles .next:hover::after {
  content: '';
  position: absolute;
  width: 33px;
  height: 22px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("../images/arrow-right-line.png");
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media (max-width: 767px) {
  .other-articles .prev:hover::after, .other-articles .next:hover::after {
    content: none;
  }
}

.other-articles .prev:hover a, .other-articles .next:hover a {
  color: #0C233D;
}

.other-articles .prev:hover a strong, .other-articles .next:hover a strong {
  color: #0C233D;
}

.other-articles .prev::after {
  -webkit-transform: rotate(180deg) translateX(-50%) !important;
          transform: rotate(180deg) translateX(-50%) !important;
  left: 24%;
  top: 45%;
}

.other-articles .next::after {
  right: 24%;
  top: 45%;
}

.other-articles .next a {
  text-align: right;
}

i.icon, button.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
}

i.icon__phone, i.icon__mail, i.icon__facebook, i.icon__instagram, i.icon__linkedin, button.icon__phone, button.icon__mail, button.icon__facebook, button.icon__instagram, button.icon__linkedin {
  background-image: url("../images/phone_icon.svg");
  width: clamp(1.66rem, calc(-0.4256rem + 3.7843vw), 3.611rem);
  height: clamp(1.66rem, calc(-0.4256rem + 3.7843vw), 3.611rem);
  border: 2px solid #00E881;
  border-radius: 15px;
  background-size: 60%;
}

i.icon__mail, button.icon__mail {
  background-image: url("../images/mail_icon.svg");
}

i.icon__facebook, i.icon__instagram, i.icon__linkedin, button.icon__facebook, button.icon__instagram, button.icon__linkedin {
  background-image: url("../images/facebook_icon.svg");
  border: 2px solid #26DCFB;
}

i.icon__instagram, button.icon__instagram {
  background-image: url("../images/instagram_icon.svg");
}

i.icon__linkedin, button.icon__linkedin {
  background-image: url("../images/linkedin_icon.svg");
}

i.icon__search, button.icon__search {
  background-image: url("../images/search_icon.svg");
  width: 21.3px;
  height: 21.3px;
  background-size: 100%;
}

i.icon__google, button.icon__google {
  background-image: url("../images/google_icon.svg");
  width: 18px;
  height: 18px;
}

i.icon__twitter, button.icon__twitter {
  background-image: url("../images/twitter_icon.svg");
  width: 22px;
  height: 20px;
}

i.icon__email, button.icon__email {
  background-image: url("../images/email_icon.svg");
  width: 20px;
  height: 18px;
}

i.icon__time, button.icon__time {
  background-image: url("../images/time_icon.svg");
  width: 33px;
  height: 33px;
}

#footer {
  background-color: #0B223E;
}

#footer .top {
  height: 52vh;
  background-image: url("../images/footer_image.svg");
  background-size: cover;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#footer .top .container {
  padding-top: 4%;
}

#footer .top h2 {
  color: #fff;
  margin-bottom: 36px;
}

#footer .top p {
  color: #00E881;
  margin-bottom: 14px;
  font-size: clamp(1rem, calc(0.8477rem + 0.7615vw), 1.66rem);
}

#footer .top a {
  margin-left: auto;
  margin-right: auto;
}

#footer .bottom .logo {
  width: clamp(5.55rem, calc(3.7175rem + 9.1627vw), 13.491rem);
  display: inline-block;
}

#footer .bottom .logo svg {
  width: 100%;
  margin-bottom: -10px;
}

@media (max-width: 1400px) {
  #footer .bottom .logo svg {
    margin-bottom: -15px;
  }
}

#footer .bottom .legal {
  padding-bottom: 88px;
  padding-top: 61px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 991px) {
  #footer .bottom .legal {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: initial;
  }
}

#footer .bottom .legal ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: auto;
}

@media (max-width: 991px) {
  #footer .bottom .legal ul {
    width: 100%;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    padding-left: 0;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-left: 0;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  #footer .bottom .legal ul {
    display: block;
  }
}

#footer .bottom .legal ul li {
  color: #fff;
  margin: 0 40px;
}

@media (max-width: 991px) {
  #footer .bottom .legal ul li {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  #footer .bottom .legal ul li {
    margin-bottom: 15px;
  }
}

#footer .bottom .legal ul li:last-child {
  margin-right: 0;
}

#footer .bottom .legal ul li a {
  font-size: clamp(0.61rem, calc(-0.3900rem + 1.4063vw), 1.11rem);
  color: #fff;
}

@media (max-width: 991px) {
  #footer .bottom .legal .copyright {
    width: 100%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
}

#footer .bottom .legal .copyright p {
  margin-bottom: 0;
  font-size: clamp(0.66rem, calc(0.3200rem + 0.4781vw), 0.83rem);
  color: #fff;
  line-height: 1.8;
}

@media (max-width: 991px) {
  #footer .bottom .legal .copyright p br {
    text-align: center;
  }
}

#footer .bottom .legal .copyright p a {
  color: #fff;
}

#footer .bottom .links {
  border-bottom: 1px solid #26DCFB;
  padding-bottom: 72px;
  padding-top: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

@media (max-width: 991px) {
  #footer .bottom .links {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

@media (max-width: 991px) {
  #footer .bottom .links .brand {
    width: 100%;
    margin-bottom: 30px;
  }
}

#footer .bottom .links .contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  gap: 50px;
}

@media (max-width: 767px) {
  #footer .bottom .links .contact {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

#footer .bottom .links .detail p {
  color: #fff;
  font-size: clamp(0.66rem, calc(0.5562rem + 0.5192vw), 1.11rem);
  margin-bottom: 0;
}

#footer .bottom .links .detail a {
  color: #00E881;
  font-weight: 600;
  font-size: clamp(0.77rem, calc(-0.1814rem + 1.7263vw), 1.66rem);
}

#footer .bottom .links .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#footer .bottom .links .item .icons {
  margin-right: 18px;
}

#footer .bottom .links .social {
  gap: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
